import React from "react";
import "../styles/privacy_policy.css";

const Privacy_Policy = () => {
  return (
    <>
      <div className="privacy-container">
        <div className="privacy-content">
          <h2>Terms and Conditions for OnCab</h2>
          <h3>1. Introducion</h3>
          <p>
            Welcome to OnCab, an online cab service platform that connects passengers with drivers for transportation services. By using our service, you agree to comply with and be bound by the following terms and conditions. Please read them carefully before accessing or using the OnCab platform.
          </p>

          <h3>2. Definitions</h3>
          <p>
            "Service" refers to the transportation services provided by drivers
            through the OnCab  platform.  "Platform" means the OnCab  mobile
            application and website.  "User" refers to any individual who
            accesses or uses the OnCab  platform, including passengers and
            drivers.  "Driver" refers to the individual providing
            transportation services through the OnCab  platform.  "Passenger"
            refers to the individual using the OnCab  platform to request
            transportation services.
          </p>
          <h3>3. Acceptance of Terms</h3>
          <p>
            By accessing or using the OnCab platform, you agree to be legally bound by these terms and conditions. If you do not agree with any part of these terms, you must not use the OnCab platform. These terms apply to all users, including guests and registered users.
          </p>
          <h3>4. Eligibility</h3>
          <p>
            To use the OnCab platform, you must:

            Be at least 18 years old.
            Have the legal capacity to enter into a binding contract.
          </p>
          <h3>5. User Accounts</h3>
          <h4>5.1 Registration</h4>
          <p>
            Users must create an account to use the OnCab platform. During registration, you agree to provide accurate, complete, and up-to-date information. You must update this information as necessary to ensure it remains accurate.
          </p>
          <h4>5.2 Account Security</h4>
          <p>
            You are responsible for maintaining the confidentiality of your account information, including your password. You agree to immediately notify OnCab of any unauthorized use of your account. OnCab is not liable for any unauthorized access or use of your account.
          </p>
          <h3>6. Use of the Service</h3>
          <h4>6.1 Passenger Responsibilities</h4>
          <p>
            Passengers must provide accurate pickup and drop-off locations.
            Passengers agree to pay the fare displayed at the end of the trip, including any additional fees.
            Passengers must respect the driver and the vehicle during the trip.
          </p>
          <h4>6.2 Driver Responsibilities</h4>
          <p>
            Drivers must possess a valid driver’s license and all necessary permits to operate a vehicle.
            Drivers must ensure their vehicle is in good working condition and provide a safe, comfortable ride.
            Drivers must comply with all local traffic laws and regulations.
          </p>
          <h3>7. Payments and Fees</h3>
          <h4>7.1 Fares</h4>
          <p>
            The fare for each trip will be calculated based on the distance traveled and time taken. Additional charges may apply, including but not limited to tolls, surcharges, or cancellation fees.
          </p>
          <h4>7.2 Payment Methods</h4>
          <p>
            Passengers must provide valid payment information before requesting a ride. OnCab accepts various payment methods, including credit/debit cards and electronic wallets.
          </p>
          <h4>7.3 Cancellation Policy</h4>
          <p>
            Passengers may cancel a trip at any time. However, a cancellation fee may apply if the driver has already accepted the trip and is en route to the passenger’s location.
          </p>
          <h3>8. Limitation of Liability</h3>
          <p>
            OnCab is a platform that connects passengers with drivers and does not provide transportation services directly. As such, OnCab is not responsible for the actions of drivers or passengers. OnCab is not liable for any direct, indirect, incidental, or consequential damages resulting from the use of the platform or its services.
          </p>
          <h3>9. Dispute Resolution</h3>
          <h4>9.1 Disputes Between Users</h4>
          <p>
            Any disputes between passengers and drivers must be resolved directly between the parties involved. OnCab may assist in facilitating communication but is not obligated to mediate or resolve disputes.
          </p>
          <h4>9.2 Arbitration</h4>
          <p>
            All disputes arising from these terms or use of the OnCab platform shall be resolved through binding arbitration in accordance with the rules of the American Arbitration Association. The arbitration shall take place in the jurisdiction where OnCab operates.

          </p>

          <h2>10. Privacy Policy</h2>
          <p>
            OnCab collects and uses personal information in accordance with its Privacy Policy. By using the platform, you consent to the collection, use, and storage of your personal data as outlined in the Privacy Policy. Your privacy is a priority for OnCab, and we take appropriate steps to safeguard your information.
          </p>
          <h3>11. Data Security and Protection</h3>
          <p>
            OnCab is committed to ensuring the security and confidentiality of user data. We employ a variety of security measures to protect your personal information from unauthorized access, use, alteration, or destruction. These measures include:

            Data Encryption: All sensitive data, including payment information, is encrypted during transmission using secure encryption protocols (e.g., SSL/TLS) to ensure that your data is safe during interactions with the platform.

            Secure Storage: Your data is stored on secure servers that are protected by firewalls and access control systems to prevent unauthorized access.

            Access Controls: Only authorized personnel at OnCab have access to your personal information, and access is restricted to necessary personnel only.

            Data Minimization: We only collect and store the minimum amount of personal data required to provide our services. For instance, we may only store your name, phone number, email address, and trip-related information.

            Regular Audits: We regularly audit our data handling practices to ensure that all security measures are up-to-date and functioning as intended.

            Data Retention: We retain your data only for as long as necessary to provide services and comply with legal obligations. Once the data is no longer required, it is safely deleted or anonymized.

            In the event of a data breach, OnCab will notify affected users as soon as possible, in compliance with applicable laws and regulations.

          </p>
          <h3>12. Changes to Terms</h3>
          <p>
            OnCab reserves the right to modify these terms and conditions at any time. Changes will be posted immediately on the OnCab platform. By continuing to use the platform after any changes are posted, you agree to the updated terms. </p>
          <h3>13. Termination</h3>
          <p>
            OnCab reserves the right to terminate or suspend your account at any time, with or without cause or notice, if you engage in conduct that violates these terms or is harmful to OnCab or its users. You may also terminate your account at any time by contacting support.
          </p>
          <h3>14. Governing Law</h3>
          <p>
            These terms and conditions shall be governed by and construed in accordance with the laws of the jurisdiction where OnCab operates. Any legal disputes will be subject to the exclusive jurisdiction of the courts in that jurisdiction.
          </p>
          <h3>15. Governing Law</h3>
          <p>
            If you have any questions or concerns regarding these Terms and Conditions, please contact OnCab at:
            <br />
             <b>Email:  support@oncabglobal.com</b> 
            <br />
            <b>Website: www.oncabglobal.com</b>
            <br />
            <b>
            Account Deletion Request: <a href="/account_deletion">www.oncabglobal.com/account_deletion</a>
            </b>
             </p>
        </div>
      </div>
    </>
  );
};

export default Privacy_Policy;
