import React, { useState } from "react";
import { Container, Row, Col, Form, FormGroup, Input } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import "../styles/contact.css";

const Loader = () => (
  <div className="loader">
    <div className="spinner"></div>
  </div>
);

const socialLinks = [
  {
    url: "https://www.facebook.com/profile.php?id=61562324425545&mibextid=ZbWKwL",
    icon: "ri-facebook-line",
  },
  {
    url: "https://www.instagram.com/oncabpk/?igsh=MTBpbHlnZWc1ZXBvYg%3D%3D",
    icon: "ri-instagram-line",
  },
  {
    url: "https://www.linkedin.com/company/oncab/?originalSubdomain=pk",
    icon: "ri-linkedin-line",
  },
];

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    subject: "",
  });
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    if (!formData.name || !formData.email || !formData.message || !formData.subject) {
      return "All fields are required!";
    }
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(formData.email)) {
      return "Please enter a valid email address!";
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    setSuccessMessage("");

    const formError = validateForm();
    if (formError) {
      setError(formError);
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await fetch("https://www.oncabglobal.com/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccessMessage("Account deletion request submitted successfully.");
        setFormData({ name: "", email: "", message: "", subject:"" });
      } else {
        setError("Something went wrong, please try again.");
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
      console.error("Error submitting form: ", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Helmet title="Account And Data Deletion Guidence">
      <CommonSection title="Account And Data Deletion Guidence" />
      <section>
        <Container>
        <Col lg="12" md="12" className="mt-4">
            <h3 className="fw-bold mb-4">Privacy Policy For Data Safety(OnCab & OnCab Pro Users):</h3>
            <h6>Introduction</h6>
            <p>
              Your privacy and safety are our top priorities. This Privacy Policy explains how we collect data, use, store, and protect your information when you use our app, as well as how you can manage or delete your data.
            </p>

            <h6>1. Information We Collect</h6>
            <p>To ensure a safe and seamless experience, we collect the following information:</p>
            <ul>
              <li>Chat Messages: Exchanged within the app and stored on our servers.</li>
              <li>Photos: Submitted for verification purposes.</li>
              <li>Email Address: Used for account creation, verification, and communication.</li>
              <li>Phone Number: Required for user verification and communication.</li>
              <li>Address: Collected for service purposes and user identification.</li>
              <li>Vehicle Information: Includes vehicle registration,vehicle photes, make, and model for verification and safety purposes.</li>
            </ul>

            <h6>2. How We Use Your Data</h6>
            <p>We collect this information to:</p>
            <ul>
              <li>Enable core functionalities of the app, such as chat and safety features.</li>
              <li>Verify users and their vehicles to ensure a secure environment.</li>
              <li>Prevent fraud and enhance the safety of all users.</li>
              <li>Communicate important updates or respond to support inquiries.</li>
            </ul>

            <h6>3. Data Storage and Security</h6>
            <p>All data, including chat messages and personal information, is securely stored on our servers. We employ industry-standard encryption, access controls, and regular audits to protect your information.</p>

            <h6>4. Data Retention</h6>
            <ul>
              <li>Chat Messages: Retained as long as your account is active.</li>
              <li>Verification Data: Retained for as long as required to ensure user safety and compliance with legal obligations.</li>
              <li>Upon account deletion, all stored data, including chat messages and verification details, will be permanently removed from our servers.</li>
            </ul>

            <h6>5. Your Rights</h6>
            <ul>
              <li>Access Your Data: You can view and update your information within the app.</li>
              <li>Delete Your Data: You may request deletion of your account and all associated data by contacting us through our account deletion form.</li>
            </ul>

            <h6>6. Third-Party Sharing</h6>
            <p>We do not share your data with third parties unless required to:</p>
            <ul>
              <li>Comply with legal obligations.</li>
              <li>Address fraud prevention or safety concerns.</li>
            </ul>

            <h6>7. Safety Measures</h6>
            <p>To ensure user safety, we use the collected information for verification purposes and to prevent any potential mishaps or fraudulent activities.</p>

            <h6>8. Updates to This Policy</h6>
            <p>We may update this Privacy Policy from time to time. Any changes will be communicated through the app or via email.</p>

            <h6>9. Contact Us</h6>
            <p>If you have any questions about this Privacy Policy or how we handle your data, please contact us at:</p>
            <ul>
              <li>Email: support@oncabglobal.com</li>
              <li><a href="/contact">Contact Page</a></li>
            </ul>
          </Col>
          <Col lg="12" md="12">
            <h3 className="fw-bold mb-4">In App Mobile App Account Deletion Instructions</h3>
            <ol>
              <li>Open the app on your mobile device.</li>
              <li>Go to "menu" located in the top left, navigation to settings.</li>
              <li>Navigate to "Delete Account".</li>
              <li>Tap the "Delete Account" button".</li>
              <li>Confirm the deletion by tapping "Yes."</li>
              <li>Your deletion request will be processed.</li>
              <li>Your Account and Data will be deleted after 15 days.</li>
              <li>If you change your mind within 15 days, contact support to cancel the deletion request.</li>
            </ol>
            <p style={{ color: "red" }}>
              * Please note that once the 15-day period has passed, your account will be permanently deleted.
            </p>
          </Col>
          <Row>
            <Col lg="7" md="7">
              <h6 className="fw-bold mb-4">Delete Account And Data By This Form</h6>

              <Form onSubmit={handleSubmit}>
                <FormGroup className="contact__form">
                  <Input
                    placeholder="Your Name"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                
                <FormGroup className="contact__form">
                  <Input
                    placeholder="Email"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup className="contact__form">
                  <Input
                    placeholder="Subject"
                    type="Text"
                    name="subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup className="contact__form">
                  <textarea
                    rows="5"
                    placeholder="Message"
                    className="textarea"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                  ></textarea>
                </FormGroup>

                <button className="contact__btn" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Sending..." : "Send Message"}
                </button>
              </Form>

              {isSubmitting && <Loader />}
              {error && <div className="error-message" style={{ color: "red" }}>{error}</div>}
              {successMessage && <div className="success-message" style={{ color: "green" }}>{successMessage}</div>}
            </Col>

            <Col lg="5" md="5">
              <div className="contact__info">
                <h6 className="fw-bold">Contact Information</h6>

                <div>
                  <h6 className="mb-0 fs-6">Office:</h6>
                  <p className="section__description mb-0">
                    A-18 3rd Floor, Humera Centre Nursery, Shahrah e Faisal, Karachi, Pakistan
                  </p>
                </div>

                <div className="d-flex align-items-center gap-2">
                  <h6 className="fs-6 mb-0">Phone:</h6>
                  <p className="section__description mb-0">+92 21 33517981</p>
                </div>

                <div className="d-flex align-items-center gap-2">
                  <h6 className="mb-0 fs-6">Email:</h6>
                  <p className="section__description mb-0">support@oncabglobal.com</p>
                </div>

                <h6 className="fw-bold mt-4">Follow Us</h6>

                <div className="d-flex align-items-center gap-4 mt-3">
                  {socialLinks.map((item, index) => (
                    <a
                      href={item.url}
                      key={index}
                      className="social__link-icon"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className={item.icon}></i>
                    </a>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
          
        </Container>
      </section>
    </Helmet>
  );
};

export default Contact;
